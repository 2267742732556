<template>
  <profile-information
    class="personal-info"
    :secondary-btn="true"
    secondary-btn-text="Update Password"
    @makeEdit="makeEdit"
    @btnTriggered="btnTriggered"
  >
    <template #title>
      <div class="personal-info__avatar">
        <input
          ref="file"
          type="file"
          accept="image/*"
          class="d-none"
          @change="change"
        />
        <div class="personal-info__image-group">
          <img :src="src" alt="avatar" class="personal-info__image rounded" />
          <button
            @click="browse()"
            class="personal-info__image-icon rounded d-flex justify-content-center align-items-center"
          >
            <span class="personal-info__uploader-cover">
              <ImageUploader />
            </span>
          </button>
        </div>
      </div>
    </template>
    <template #body>
      <b-row class="mb-3">
        <b-col sm="6">
          <strong class="d-block mb-2">Full Name</strong>
          <span>{{ user.name }}</span>
        </b-col>
        <b-col sm="6">
          <strong class="d-block mb-2">Gender</strong>
          <span>{{ user.gender | capitalize }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <strong class="d-block mb-2">Phone Number</strong>
          <span>{{ user.phone }}</span>
        </b-col>
        <b-col sm="4">
          <strong class="d-block mb-2">Email Address</strong>
          <span>{{ user.email }}</span>
        </b-col>
        <b-col sm="4">
          <strong class="d-block mb-2">Date of Birth</strong>
          <span>{{ user.date_of_birth }}</span>
        </b-col>
      </b-row>
      <div class="personal-info__skills-header border-bottom mt-5 mb-3">
        <h4>Skills Gained</h4>
      </div>
      <div class="personal-info__skills-body d-flex flex-wrap">
        <SkillCard
          v-for="(skill, i) in user.skills_gained"
          :key="i"
          :skill="skill"
          class="mb-2 mr-1 mr-sm-2"
        />
      </div>
    </template>
  </profile-information>
</template>

<script>
import SkillCard from "../skills/SkillCard.vue";
import ProfileInformation from "./ProfileInformation.vue";
import DefaultAvatar from "../../assets/img/avatar.png";
import ImageUploader from "../svg/ImageUploader.vue";
export default {
  components: {
    SkillCard,
    ProfileInformation,
    ImageUploader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    value: File,
  },
  data() {
    return {
      src: DefaultAvatar,
      file: null,
    };
  },
  computed: {
    avatar() {
      return this.user?.avatar || require("@/assets/img/avatar.png");
    },
  },
  methods: {
    makeEdit() {
      this.$emit("makeEdit");
    },
    btnTriggered() {
      this.$emit("btnTriggered");
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      this.file = e.target.files[0];

      this.$emit("input", this.file);

      let reader = new FileReader();

      reader.readAsDataURL(this.file);

      reader.onload = (e) => {
        this.src = e.target.result;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-info {
  h4 {
    font-size: 0.875rem;
    font-weight: 600;
  }
  &__image-group {
    position: relative;
    display: inline-block;
  }
  &__image {
    width: 64px;
    height: 64px;
  }
  &__image-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: none;
    background: none;
  }
  &__uploader-cover {
    opacity: 0;
  }
  &__uploader-cover:hover {
    display: block;
    background-color: #333;
    opacity: 0.6;
    padding: 6px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
}
</style>
